import { Box, Stack, Typography } from '@mui/material'
import { FC, Fragment, ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Download } from '@mui/icons-material'
import { GRAY_900 } from 'constants/styling/theme'
import { MUIBadge } from '../MUIBadge'
import { MUIButton } from '../MUIButton'
import { MUIDivider } from '../MUIDivider'
import { SectionedBorderBox } from '../SectionedBorderBox'

interface Props {
  /** Title for the section box */
  boxTitle?: ReactNode | string
  /** Slot for the upload component */
  uploadSlot: ReactNode
  /** Variant of component */
  variant: 'owners' | 'representatives'
  /** Url of sample */
  sampleUrl: string
  /** Whether to show the submit button or not. */
  showSubmit?: boolean
  /** Callback when the submit button is clicked. */
  onSubmit?: () => void
  /** Badge text if empty then the badge is not displayed. */
  badgeText?: string
}

/**
 * @component
 * Renders a component wrapper for uploading power of attorney documents.
 *
 * @example
 * <PowerOfAttorneyUpload
 *   boxTitle="Upload Power of Attorney"
 *   uploadSlot={<FileUpload />}
 *   sampleUrl="https://example.com/sample.pdf"
 *   variant="default"
 *   showSubmit={false}
 * />
 */
export const PowerOfAttorneyUpload: FC<Props> = ({
  boxTitle,
  uploadSlot,
  sampleUrl,
  variant,
  showSubmit,
  badgeText,
  onSubmit,
}) => {
  const { t } = useTranslation(['common', 'power_of_attorney'])

  return (
    <SectionedBorderBox
      title={
        <Stack direction="row" flexWrap="wrap" gap={2} alignItems="center">
          <Typography color={GRAY_900} variant='text-lg' fontWeight={600}>{boxTitle || t(`power_of_attorney:upload_box_title_${variant}`)}</Typography>
          {badgeText && <MUIBadge color='orange' label={badgeText} />}
        </Stack>
      }
    >

      <Stack direction="row" flexWrap="wrap" gap={2}>

        <Stack
          flex="1 1 30%"
          direction="column"
          gap={1}
        >

          <Typography variant="h3" sx={{ margin: '0', lineHeight: 'normal' }}>
            <Trans parent={null}>
              {t('power_of_attorney:upload_content_title')}
            </Trans>
          </Typography>

          <Typography variant="text-sm">
            <Trans parent={null}>
              {t(`power_of_attorney:upload_content_description_${variant}`)}
            </Trans>
          </Typography>

          <Box sx={{ mt: 2 }}>
            <MUIButton
              startIcon={<Download />}
              onClick={() => window.open(sampleUrl, '__blank', 'noopener noreferrer nofollow')}
            >
              {t('Download')}
            </MUIButton>
          </Box>

        </Stack>

        <Box
          flex="1 0 200px"
          sx={{
            minHeight: '200px',
          }}
        >
          {uploadSlot}
        </Box>

      </Stack>

      {showSubmit && (
        <Fragment>
          <MUIDivider />
          <Stack alignItems="flex-end">
            <MUIButton onClick={onSubmit}>
              {t('Submit')}
            </MUIButton>
          </Stack>
        </Fragment>
      )}

    </SectionedBorderBox>
  )
}
