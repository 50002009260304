import { BEIGE_600, GRAY_100, GRAY_400, GRAY_500, GRAY_700, GRAY_800, GRAY_900, WHITE } from 'constants/styling/theme'
import { FileManipulationKey, VisualFileType } from 'constants/visual'
import { FileRejection, useDropzone } from 'react-dropzone'
import { FileTypes, acceptedProductAuthoritiesDocumentMimetypes } from 'constants/misc'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { UploadLimitError, UploadVisualItem } from 'components/common/UploadVisuals'
import { UploadOrderDocumentType, useClientGalleryPowerOfAttorneyUploadUrl } from 'dataQueries'

import { APIRequestErrorType } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Box from '@mui/material/Box'
import { CircleIcon } from 'components/common/CircleIcon'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import Collapse from '@mui/material/Collapse'
import Link from '@mui/material/Link'
import { RootStore } from 'models/redux'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useClientGallery } from '../../pages/Client/ClientGallery/_main/contexts/ClientGallery.context'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useImageRegex } from 'utils/hooks'
import { useSelector } from 'react-redux'

/**
 * @interface Props for the FloorPlanLogoUpload component.
 */
interface Props {
  /** Title to be displayed in the container. */
  title: string
  /** Whether a file is being dragged over the browser window */
  isDraggingFile: boolean
  /** File types which can be uploaded. */
  acceptedFileTypes: FileTypes[]
  /** Maximum number of uploaded files. */
  maxNumberOfFiles?: number // Default is 1
}

/**
 * @component
 * Renders a file upload component for uploading power of attorney PDF files.
 *
 * @example
 * <PowerOfAttorneyFileUpload isDraggingFile={false} />
 */
export const PowerOfAttorneyFileUpload: React.FC<Props> = ({
  title,
  isDraggingFile,
  acceptedFileTypes,
  maxNumberOfFiles = 1,
}) => {
  const { t } = useTranslation(['upload_files'])
  const { dealId } = useGalleryDeal()
  const { assignmentId } = useClientGallery()

  const imageNameReplacePattern = useImageRegex(assignmentId)

  const [fileSizeMap, setFileSizeMap] = useState<Record<string, number>>({})

  const uploadClientDocument = useClientGalleryPowerOfAttorneyUploadUrl()
  const uploadVisuals = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_VISUAL]?.[assignmentId]?.[FileManipulationKey.UPLOAD])
  const downloadProductVisuals = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_VISUAL]?.[assignmentId]?.[FileManipulationKey.DOWNLOAD])
  const uploadVisualsEntries = useMemo(() => (uploadVisuals ? Object.entries(uploadVisuals).filter(([key, allTypes]) => allTypes?.[VisualFileType.RAW]) : []), [uploadVisuals])
  // TODO: Check the filtering
  const downloadVisualsEntries = useMemo(() => (downloadProductVisuals ? Object.entries(downloadProductVisuals).filter(([key, allTypes]) => allTypes?.[VisualFileType.RAW_THUMB]) : []), [downloadProductVisuals])
  const allVisuals = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LIST_VISUALS]?.[assignmentId]?.[VisualFileType.RAW])
  const listedVisualKeys = useMemo(() => allVisuals?.data ? Object.values(allVisuals.data?.visuals?.[Number(assignmentId)]) : [], [allVisuals?.data, assignmentId])

  const uploadVisualsKeyToOriginalNameDictionary = useMemo(() => Object.fromEntries(uploadVisualsEntries.map(([key, allTypes]) => [key, allTypes?.[VisualFileType.RAW]?.originalFilename || ''] as [string, string]).filter(([key, originalName]) => !!originalName)), [uploadVisualsEntries])
  const uploadVisualsOriginalFilenames = useMemo(() => Object.values(uploadVisualsKeyToOriginalNameDictionary), [uploadVisualsKeyToOriginalNameDictionary])
  const visualsCount = useMemo(() => listedVisualKeys.length, [listedVisualKeys.length])
  const isDropzoneDisabled = useMemo(() => uploadClientDocument.isPending || uploadClientDocument.isSuccess, [uploadClientDocument.isPending, uploadClientDocument.isSuccess])

  const sortFunction = useCallback((aKey: string, bKey: string) => {
    if (aKey > bKey) return 1
    else if (aKey < bKey) return -1
    else return 0
  }, [])

  const sortEntriesFunction = useCallback(([aKey, aObject]: [string, any], [bKey, bObject]: [string, any]) => {
    return sortFunction(aKey, bKey)
  }, [sortFunction])

  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections && fileRejections.length > 0) {
      if (fileRejections.length > 1) {
        return alert(
          <Trans t={t} i18nKey="unsupported_upload_max_number_files" values={{ maxNumberOfFiles }}>
            <Typography variant='text-sm' color={GRAY_900}></Typography>
          </Trans>
        )
      }

      const formats: string[] = []
      for (let fileRejection of fileRejections) {
        const split = fileRejection.file.name.split('.')
        if (split.length < 2) formats.push(fileRejection.file.name)
        else formats.push(split.slice(1).join('.'))
      }
      const formatsString = Array.from(new Set(formats).keys()).join(', ')
      alert(`${t('unsupported_file_format')}\n${formatsString}`)
    }

    const duplicateFiles = []
    for (let file of acceptedFiles) {
      if (!uploadVisualsOriginalFilenames.includes(file.name)) {
        setFileSizeMap((prev) => ({ ...prev, [file.name]: file.size }))
        uploadClientDocument.mutate({
          orderId: dealId,
          filename: file.name,
          contentType: file.type,
          type: UploadOrderDocumentType.POWER_OF_ATTORNEY
        })
      } else duplicateFiles.push(file.name)
    }
    if (duplicateFiles.length > 0) {
      alert(
        <Trans t={t} i18nKey="duplicate_file_alert" values={{ files: duplicateFiles.join(', ') }}>
          <Typography variant='text-sm' color={GRAY_900}></Typography>
        </Trans>
      )
    }
  }, [dealId, maxNumberOfFiles, t, uploadClientDocument, uploadVisualsOriginalFilenames])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedProductAuthoritiesDocumentMimetypes,
    maxFiles: maxNumberOfFiles,
    disabled: isDropzoneDisabled
  })

  const onDeleteImage = useCallback((reactKey: string) => {
    // TODO: Handle removing of the file
  }, [])

  const preloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = listedVisualKeys.map(key => {
      const reactKey = key
      const fileName = key.replace(imageNameReplacePattern, '')
      return [key, reactKey, (
        <UploadVisualItem
          key={key}
          reactKey={key}
          type='preload'
          fileName={fileName}
          onDelete={onDeleteImage}
          size={fileSizeMap[fileName] ?? 0}
        />
      )]
    })

    return tupleArray
  }, [listedVisualKeys, imageNameReplacePattern, onDeleteImage, fileSizeMap])

  const downloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = downloadVisualsEntries.map(([key, allTypes]) => {
      const img = allTypes?.[VisualFileType.RAW_THUMB]
      if (!img) throw new Error(`Image does not exist for key: ${key} and visual type: ${VisualFileType.RAW_THUMB}`)
      const isError = img.request.error_type !== APIRequestErrorType.NONE
      const label = img.file?.name.replace(imageNameReplacePattern, '') + (img.originalFilename ? ` | ${img.originalFilename}` : '')
      const reactKey = key

      return [key, reactKey, (
        <UploadVisualItem
          key={key}
          reactKey={key}
          type='download'
          image={img}
          label={label}
          isError={isError}
          onDelete={onDeleteImage}
          size={fileSizeMap[img.originalFilename || ''] ?? 0}
        />
      )]
    })

    return tupleArray
  }, [downloadVisualsEntries, imageNameReplacePattern, onDeleteImage, fileSizeMap])

  const uploadListing = useCallback(() => {
    const tupleArray: [string, string, string, JSX.Element][] = uploadVisualsEntries.map(([key, allTypes]) => {
      const img = allTypes?.[VisualFileType.RAW]
      if (!img) throw new Error(`Image does not exist for key: ${key} and visual type: ${VisualFileType.RAW}`)
      // For sorting fresh uploads on top
      const sortKey = (img.replaces || imageNameReplacePattern.exec(key)) ? key : `${assignmentId}-bkbn-ZZZZZ-${img.droppedIn}-${key}`
      const reactKey = img.originalFilename ?? key
      const fileName = img.file ? img.file.name.replace(imageNameReplacePattern, '') : ''
      const originalFilename = img.originalFilename ? ` | ${img.originalFilename}` : ''
      const label = fileName ? fileName + originalFilename : originalFilename

      return [key, reactKey, sortKey, (
        <UploadVisualItem
          key={key}
          reactKey={key}
          image={img}
          type='uploading'
          label={label}
          onDelete={onDeleteImage}
          size={fileSizeMap[originalFilename || ''] ?? 0}
        />
      )]
    })

    return tupleArray
  }, [uploadVisualsEntries, imageNameReplacePattern, assignmentId, onDeleteImage, fileSizeMap])

  const visualsListing = useCallback(() => {
    const map: Map<string, [string, JSX.Element]> = new Map()

    for (let [key, reactKey, node] of preloadListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, node] of downloadListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, sortKey, node] of uploadListing()) {
      map.delete(key)
      map.set(sortKey, [reactKey, node])
    }

    const entries: [string, [string, JSX.Element]][] = Array.from(map.entries())
    const sortedEntries = [...entries].sort(sortEntriesFunction)

    return (
      <Fragment>
        {sortedEntries.map(([key, [reactKey, node]]) => (
          <Fragment key={reactKey}>
            {node}
          </Fragment>
        ))}
      </Fragment>
    )
  }, [sortEntriesFunction, preloadListing, downloadListing, uploadListing])

  return (
    <BorderBoxWrapper elevation='sm' padding={2} borderColor={BEIGE_600}>

      {/** TITLE */}
      <Stack marginBottom={1} gap={0.5} alignItems="center" direction="row">
        <Typography variant='text-sm' color={GRAY_700} fontWeight={500}>
          {title}
        </Typography>
      </Stack>

      {/** UPLOAD ZONE */}
      <div {...getRootProps()}>
        <BorderBoxWrapper
          padding={4}
          elevation='none'
          textAlign="center"
          backgroundColor={isDropzoneDisabled ? GRAY_100 : undefined}
          sx={{ cursor: isDropzoneDisabled ? 'not-allowed' : 'pointer' }}
          border={`2px dashed ${GRAY_400}`}
        >

          {/** UPLOAD INPUT */}
          <input {...getInputProps()} />

          {/** SUBMIT & DROP */}
          <Stack gap={1} direction="column" alignItems="center">
            <CircleIcon
              size="5rem"
              icon={<CloudUploadOutlinedIcon fontSize="large" sx={{ color: isDropzoneDisabled ? GRAY_500 : GRAY_800 }} />}
              circleColor={isDropzoneDisabled ? WHITE : BEIGE_600}
            />

            {isDragActive || isDraggingFile
              ? (
                <Typography variant="text-sm" color={GRAY_900} fontWeight={500}>
                  {t('drop_here')}
                </Typography>
              )
              : (
                <Fragment>
                  <Box>
                    <Link variant="text-sm" color={GRAY_900} fontWeight={500}>
                      {t('upload_dropzone_click')}
                    </Link>
                    <Typography variant="text-sm" color={GRAY_900}>
                      {` ${t('upload_dropzone_drag_drop')}`}
                    </Typography>
                  </Box>

                  <Typography variant="text-sm" color={GRAY_700}>
                    <Trans t={t} i18nKey="upload_dropzone_suggestion" values={{ fileTypes: acceptedFileTypes.join(', ') }}>
                      <Typography variant='text-sm' color={GRAY_900}></Typography>
                    </Trans>
                  </Typography>
                </Fragment>
              )
            }
          </Stack>
        </BorderBoxWrapper>
      </div>

      {/** UPLOADED FILES*/}
      {visualsCount > 0 && visualsListing()}

      {/** ERROR (visuals limit exceeded) */}
      <div>
        <Collapse in={downloadVisualsEntries.length > 1}>
          <UploadLimitError />
        </Collapse>
      </div>

    </BorderBoxWrapper>
  )
}
