export enum FileMimetypes {
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_ARW = 'image/x-sony-arw',
  IMAGE_CR2 = 'image/x-canon-cr2',
  IMAGE_CR3 = 'image/x-canon-cr3',
  IMAGE_CRW = 'image/x-canon-crw',
  IMAGE_DCR = 'image/x-kodak-dcr',
  IMAGE_DNG = 'image/x-adobe-dng',
  IMAGE_NEF = 'image/x-nikon-nef',
  IMAGE_RAW = 'image/x-panasonic-raw',
  IMAGE_SR2 = 'image/x-sony-sr2',
  IMAGE_SRF = 'image/x-sony-srf',
  IMAGE_ALL = 'image/*',
  PDF = 'application/pdf',
}

export enum FileTypes {
  JPEG = '.jpeg',
  JPG = '.jpg',
  SVG = '.svg',
  PNG = '.png',
  PDF = '.pdf',
}

export const acceptedProductVisualMimetypes: Record<string, string[]> = {
  [FileMimetypes.IMAGE_JPEG]: [],
  [FileMimetypes.IMAGE_ARW]: [],
  [FileMimetypes.IMAGE_CR2]: [],
  [FileMimetypes.IMAGE_CR3]: [],
  [FileMimetypes.IMAGE_CRW]: [],
  [FileMimetypes.IMAGE_DCR]: [],
  [FileMimetypes.IMAGE_DNG]: [],
  [FileMimetypes.IMAGE_NEF]: [],
  [FileMimetypes.IMAGE_RAW]: [],
  [FileMimetypes.IMAGE_SR2]: [],
  [FileMimetypes.IMAGE_SRF]: [],
}

export const acceptedProductDocumentMimetypes: Record<string, string[]> = {
  [FileMimetypes.IMAGE_JPEG]: [],
}

export const acceptedProductAuthoritiesDocumentMimetypes: Record<string, string[]> = {
  [FileMimetypes.PDF]: [],
}
