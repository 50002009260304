import { FileTypes } from 'constants/misc'
import { PowerOfAttorneyFileUpload } from 'components/common/PowerOfAttorneyFileUpload'
import { PowerOfAttorneyUpload } from 'components/common/PowerOfAttorneyUpload'
import React from 'react'
import Stack from '@mui/material/Stack'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * Renders the component for uploading power of attorney documents in the client gallery.
 * 
 * @example
 * <ClientGalleryUploadPowerAttorney />
 */
export const ClientGalleryUploadPowerAttorney: React.FC = () => {
  const { t } = useTranslation(['power_of_attorney'])
  const { dealData } = useGalleryDeal()

  return (
    <Stack gap={2} marginTop={2}>

      {dealData?.powerOfAttorney.requiresPowerOfAttorney &&
        <PowerOfAttorneyUpload
          boxTitle={t('upload_box_title_owners')}
          variant="owners"
          sampleUrl='https://example.com/sample.pdf'
          badgeText={t('awaiting_signature')}
          showSubmit={true}
          onSubmit={() => console.log('Submit')}
          uploadSlot={
            <PowerOfAttorneyFileUpload
              title="Upload Power of Attorney"
              acceptedFileTypes={[FileTypes.PDF]}
              isDraggingFile={false}
            />
          }
        />
      }

      {dealData?.powerOfAttorney.requiresPowerOfAttorneyRepresentatives &&
        <PowerOfAttorneyUpload
          boxTitle={t('upload_box_title_representatives')}
          variant="representatives"
          sampleUrl='https://example.com/sample.pdf'
          badgeText={t('awaiting_signature')}
          showSubmit={true}
          onSubmit={() => console.log('Submit')}
          uploadSlot={
            <PowerOfAttorneyFileUpload
              title="Upload Power of Attorney"
              acceptedFileTypes={[FileTypes.PDF]}
              isDraggingFile={false}
            />
          }
        />
      }

    </Stack>
  )
}
