import { FC, ReactNode, createContext, useContext, useMemo } from 'react'
import { ProductKind, ProductType } from 'constants/product'

import { FeatureFlag } from 'utils/featureFlags'
import { ReactComponent as OrderTypeDocuments } from 'assets/img/icons/order_type_documents.svg'
import { ReactComponent as OrderTypeDroneIcon } from 'assets/img/icons/order_type_drone.svg'
import { ReactComponent as OrderTypeEditingIcon } from 'assets/img/icons/order_type_editing.svg'
import { ReactComponent as OrderTypeFloorPlanIcon } from 'assets/img/icons/order_type_floor_plan.svg'
import { ReactComponent as OrderTypePhotoIcon } from 'assets/img/icons/order_type_photo.svg'
import { ReactComponent as OrderTypeRenderingIcon } from 'assets/img/icons/order_type_rendering.svg'
import { ReactComponent as OrderTypeStagingIcon } from 'assets/img/icons/order_type_staging.svg'
import { ReactComponent as OrderTypeVideoIcon } from 'assets/img/icons/order_type_video.svg'
import { ReactComponent as OrderTypeVirtualTourIcon } from 'assets/img/icons/order_type_virtual_visit.svg'
import { ProductDTO } from 'models/product'
import { VisualFileType } from 'constants/visual'
import { useFlag } from '@unleash/proxy-client-react'
import { useGalleryAssignment } from './GalleryAssignment.context'
import { useGalleryVisualType } from './GalleryVisualType.context'

interface GalleryProductInterface {
  product?: ProductDTO
  isVideo: boolean
  isVirtualVisit: boolean
  isFloorPlan: boolean
  isFloorPlanWithTypeVirtualVisit: boolean
  isDroneProduct: boolean
  isDocumentProduct: boolean
  isVirtualVisitHD: boolean
  getProductKindIcon: (kind: ProductKind) => ReactNode
}

const defaultGalleryProductValue: GalleryProductInterface = {
  isVideo: false,
  isVirtualVisit: false,
  isFloorPlan: false,
  isFloorPlanWithTypeVirtualVisit: false,
  isDroneProduct: false,
  isDocumentProduct: false,
  isVirtualVisitHD: false,
  getProductKindIcon: () => <></>
}

/** Gallery product context */
export const GalleryProductContext = createContext<GalleryProductInterface>(defaultGalleryProductValue)
/** Gallery product context hook */
export const useGalleryProduct = (): GalleryProductInterface => useContext(GalleryProductContext)

/** Context provider for gallery product */
export const GalleryProductContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
}> = ({
  children,
}) => {
    const allowAuthoritiesDocs = useFlag(FeatureFlag.ALLOW_AUTHORITIES_DOCUMENTS)

    const {
      assignmentData,
      product
    } = useGalleryAssignment()
    const { normalizedOriginalType } = useGalleryVisualType()

    const isVideo = useMemo(() => (
      assignmentData?.type === ProductType.VIDEO
    ), [assignmentData])

    // Product type is virtual visit also know as virtual tour matterport and kind HD
    const isVirtualVisitHD = useMemo(() => (
      (assignmentData?.type === ProductType.VIRTUAL_VISIT && product?.kind === ProductKind.HD)
    ), [assignmentData, product])

    const isVirtualVisit = useMemo(() => {
      if (isVirtualVisitHD) return true

      if (assignmentData?.type === ProductType.VIRTUAL_VISIT && product?.kind === ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT) {
        return normalizedOriginalType === VisualFileType.RAW
      }
      if (assignmentData?.type === ProductType.FLOOR_PLAN && product?.kind !== ProductKind.MOBILE_APPLICATION_FLOOR_PLAN) {
        return normalizedOriginalType === VisualFileType.RAW
      }
      return false
    }, [isVirtualVisitHD, assignmentData?.type, product?.kind, normalizedOriginalType])

    const isFloorPlan = useMemo(() => (
      (assignmentData?.type === ProductType.FLOOR_PLAN) ||
      (assignmentData?.type === ProductType.VIRTUAL_VISIT && product?.kind === ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT)
    ), [assignmentData, product])

    const isFloorPlanWithTypeVirtualVisit = useMemo(() => !!product && (product.kind === ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT) && (product.type === ProductType.VIRTUAL_VISIT), [product])

    const isDroneProduct = useMemo(() => !!product && (product.kind === ProductKind.DRONE_PHOTO || product.kind === ProductKind.STANDARD_DRONE_VIDEO), [product])

    const isDocumentProduct = useMemo(() => allowAuthoritiesDocs && !!product && (product.kind === ProductKind.AUTHORITIES_DOCUMENTS), [allowAuthoritiesDocs, product])

    /**
     * This function returns an icon component based on the type of product kind passed as an argument.
     * If the product kind doesn't match any defined cases, it defaults to returning a default photo icon.
     * @param kind - The product kind to determine the icon for.
     * @returns A ReactNode representing the icon component for the given product kind.
     */
    const getProductKindIcon = (kind: ProductKind): ReactNode => {
      switch (kind) {
        case ProductKind.GROUND_PHOTO_PRESTIGE:
        case ProductKind.GROUND_PHOTO:
        case ProductKind.PORTRAIT:
        case ProductKind.MATTERPORT_PHOTO:
          return <OrderTypePhotoIcon />

        case ProductKind.RESIDENTIAL_STAGING:
        case ProductKind.COMMERCIAL_STAGING:
          return <OrderTypeStagingIcon />

        case ProductKind.HD:
          return <OrderTypeVirtualTourIcon />

        case ProductKind.PRESTIGE_GROUND_AND_DRONE_VIDEO:
        case ProductKind.STANDARD_GROUND_AND_DRONE_VIDEO:
        case ProductKind.REEL_VIDEO:
        case ProductKind.STANDARD_DRONE_VIDEO:
        case ProductKind.STANDARD_GROUND_VIDEO:
        case ProductKind.SLIDESHOW:
        case ProductKind.TEASER_VIDEO:
          return <OrderTypeVideoIcon />

        case ProductKind.MOBILE_APPLICATION_FLOOR_PLAN:
        case ProductKind.FLOOR_PLAN_EDITING_2D:
        case ProductKind.FLOOR_PLAN_EDITING_3D:
        case ProductKind.MATTERPORT_FLOOR_PLAN_WITHOUT_VISIT:
        case ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT:
          return <OrderTypeFloorPlanIcon />

        case ProductKind.DRONE_PHOTO:
          return <OrderTypeDroneIcon />

        case ProductKind.PHOTO:
          return <OrderTypeEditingIcon />

        case ProductKind.RESIDENTIAL_RENDERING:
        case ProductKind.COMMERCIAL_RENDERING:
          return <OrderTypeRenderingIcon />

        case ProductKind.AUTHORITIES_DOCUMENTS:
          return <OrderTypeDocuments />

        default:
          return <OrderTypePhotoIcon />
      }
    }

    return (
      <GalleryProductContext.Provider
        value={{
          product,
          isVideo,
          isVirtualVisit,
          isFloorPlan,
          isFloorPlanWithTypeVirtualVisit,
          isDroneProduct,
          isDocumentProduct,
          isVirtualVisitHD,
          getProductKindIcon
        }}
      >
        {children}
      </GalleryProductContext.Provider>
    )
  }
